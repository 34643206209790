import React, { useEffect, useState } from "react";
import "../../styles/Navbar.css";
import Hamburger from "./Hamburger";
// import parik from "../../assets/parik.png";
import parik from "../../assets/ppp.png";
import { Link, withRouter } from "react-router-dom";
import call from "../../assets/call.png";
import open from "../../assets/ham.svg";
import close from "../../assets/close.svg";

// https://victorstone.co.uk/wp-content/themes/victorstone/src/assets/icons/ham.svg
// https://victorstone.co.uk/wp-content/themes/victorstone/src/assets/icons/ham-close.svg

function Navbar({ history }) {
  const [state, setState] = useState({
    initial: false,
    clicked: null,
    menuName: open,
    paraName: "Menu",
  });

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    history.listen(() => {
      setState({ clicked: false, menuName: open, paraName: "Menu" });
    });
  }, [history]);

  const handleMenu = () => {
    disableMenu();
    if (state.initial === false) {
      setState({
        initial: null,
        clicked: true,
        menuName: close,
        paraName: "Close",
      });
    } else if (state.clicked === true) {
      setState({
        clicked: !state.clicked,
        menuName: open,
        paraName: "Menu",
      });
    } else if (state.clicked === false) {
      setState({
        clicked: !state.clicked,
        menuName: close,
        paraName: "Close",
      });
    }
  };

  const disableMenu = () => {
    setDisabled(!disabled);
    setTimeout(() => {
      setDisabled(false);
    }, 1200);
  };
  // "https://victorstone.co.uk/wp-content/themes/victorstone/src/assets/icons/ham.svg"

  const scroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="navbar__div1">
      <div className="navbar__div2">
        <div className="navbar__div3">
          <div
            className="navbar__div4"
            disabled={disabled}
            onClick={handleMenu}
            style={{ cursor: "default" }}
          >
            <img src={state.menuName} alt="logo" className="navbar__logo" />
            <p>{state.paraName}</p>
          </div>
          <div className="navbar__div5">
            <Link to="/">
              <img src={parik} alt="Companany Logo" className="cmpLogo" />
            </Link>
          </div>
          <Link
            to="/contact"
            style={{ textDecoration: "none", color: "#fff" }}
            onClick={scroll}
          >
            <div className="navbar__div6">
              <img src={call} alt="email" className="second__icon" />
              <p>Contact</p>
            </div>
          </Link>
        </div>
      </div>
      <div className="navbar__div7">
        <Hamburger state={state} />
        {/* <Ham /> */}
      </div>
      {/* <Ham /> */}
    </div>
  );
}

export default withRouter(Navbar);
