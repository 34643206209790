import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "../../styles/HeroSection.css";
import { motion } from "framer-motion";
import pvideo from "../../assets/Parikh House_Trim.mp4"
import building from "../../assets/building2-1.png"
import background from "../../assets/background2.jpg"

const HeroSection = () => {
  function scrollFunction() {
  //   if (
  //     document.body.scrollTop > 80 ||
  //     document.documentElement.scrollTop > 80
  //   ) {
  //     document.getElementById("hero__div3").style.height = "100vh";
  //     document.getElementById("hero__div3").style.width = "100vw";
  //     document.getElementById("hero__video").style.objectFit = "cover";
  //     document.getElementById("hero__div3").style.overflow = "hidden";
  //     document.getElementById("hero__div3").style.marginTop = "0vw";
  //     document.getElementById("hero__div3").style.marginLeft = "0vw";
  //     document.getElementById("hero__video").style.transition = "0.5s ease";
  //     document.getElementById("hero__div3").style.position = "relative";
  //     // document.getElementById("hero__video").style.opacity = 1;
  //     // document.getElementById("hero__img1").style.opacity = 1;

  //     document.getElementById("hero__img1").style.height = "100vw";
  //     document.getElementById("hero__img1").style.width = "100vw";
  //     document.getElementById("hero__img1").style.objectFit = "cover";
  //     document.getElementById("hero__img1").style.marginTop = "0vw";
  //     document.getElementById("hero__img1").style.marginLeft = "0vw";
  //     document.getElementById("hero__img1").style.opacity = 0;

  //     document.getElementById("hero__img2").style.opacity = 0;
  //     document.getElementById("hero__img2").style.zIndex = 11;
  //     document.getElementById("hero__img2").style.overflow = "hidden";

  //     document.getElementById("hero__div1").style.overflow = "hidden";
  //   } else {
  //     document.getElementById("hero__div3").style.height = "75vw";
  //     document.getElementById("hero__div3").style.width = "30vw";
  //     document.getElementById("hero__div3").style.marginTop = "15vw";
  //     document.getElementById("hero__div3").style.marginLeft = "35vw";
  //     document.getElementById("hero__div3").style.overflow = "hidden";
  //     document.getElementById("hero__div3").style.position = "relative";
  //     document.getElementById("hero__video").style.transition = "0.5s ease";
  //     document.getElementById("hero__video").style.zIndex = -999;

  //     document.getElementById("hero__img1").style.height = "43vw";
  //     document.getElementById("hero__img1").style.width = "30vw";
  //     document.getElementById("hero__img1").style.marginTop = "15vw";
  //     document.getElementById("hero__img1").style.marginLeft = "35vw";
  //     document.getElementById("hero__img1").style.position = "absolute";
  //     document.getElementById("hero__img1").style.opacity = 1;
  //     document.getElementById("hero__img1").style.zIndex = -1;

  //     document.getElementById("hero__img2").style.height = "58vw";
  //     document.getElementById("hero__img2").style.width = "33vw";
  //     document.getElementById("hero__img2").style.marginTop = "1vw";
  //     document.getElementById("hero__img2").style.marginLeft = "33vw";
  //     document.getElementById("hero__img2").style.position = "fixed";
  //     document.getElementById("hero__img2").style.opacity = 1;
  //     document.getElementById("hero__img2").style.zIndex = 1;
  //     document.getElementById("hero__img2").style.overflow = "hidden";

  //     document.getElementById("hero__div1").style.overflow = "hidden";
  //     // document.getElementById("hero__video").style.opacity = 0;
  //   }
  // }


  if (
    document.body.scrollTop > 80 ||
    document.documentElement.scrollTop > 80
  ) {
    document.getElementById("hero__div3").style.height = "100vh";
    document.getElementById("hero__div3").style.width = "100vw";
    document.getElementById("hero__video").style.objectFit = "cover";
    document.getElementById("hero__div3").style.overflow = "hidden";
    document.getElementById("hero__div3").style.marginTop = "0vw";
    document.getElementById("hero__div3").style.marginLeft = "0vw";
    document.getElementById("hero__div3").style.marginRight = "0vw";
    document.getElementById("hero__video").style.transition = "0.5s ease";
    document.getElementById("hero__div3").style.position = "relative";
    // document.getElementById("hero__video").style.opacity = 1;
    // document.getElementById("hero__img1").style.opacity = 1;

    document.getElementById("hero__img1").style.height = "100vw";
    document.getElementById("hero__img1").style.width = "100vw";
    document.getElementById("hero__img1").style.objectFit = "cover";
    document.getElementById("hero__img1").style.marginTop = "0vw";
    document.getElementById("hero__img1").style.marginLeft = "0vw";
    document.getElementById("hero__img1").style.marginRight = "0vw";
    document.getElementById("hero__img1").style.opacity = 0;

    document.getElementById("hero__img2").style.opacity = 0;
    document.getElementById("hero__img2").style.zIndex = 11;
    document.getElementById("hero__img2").style.overflow = "hidden";

    document.getElementById("hero__div1").style.overflow = "hidden";
  } else {
    document.getElementById("hero__div3").style.height = "75vw";
    document.getElementById("hero__div3").style.width = "68vw";
    document.getElementById("hero__div3").style.marginTop = "15vw";
    document.getElementById("hero__div3").style.marginLeft = "17.5vw";
    document.getElementById("hero__div3").style.overflow = "hidden";
    document.getElementById("hero__div3").style.position = "relative";
    document.getElementById("hero__video").style.transition = "0.5s ease";
    document.getElementById("hero__video").style.zIndex = -999;

    document.getElementById("hero__img1").style.height = "60vw";
    document.getElementById("hero__img1").style.width = "68vw";
    document.getElementById("hero__img1").style.marginTop = "14vw";
    document.getElementById("hero__img1").style.marginLeft = "17.5vw";
    document.getElementById("hero__img1").style.position = "absolute";
    document.getElementById("hero__img1").style.opacity = 1;
    document.getElementById("hero__img1").style.zIndex = -1;

    document.getElementById("hero__img2").style.height = "36vw";
    document.getElementById("hero__img2").style.width = "68vw";
    document.getElementById("hero__img2").style.marginTop = "23vw";
    document.getElementById("hero__img2").style.marginLeft = "17.5vw";
    document.getElementById("hero__img2").style.position = "fixed";
    document.getElementById("hero__img2").style.opacity = 1;
    document.getElementById("hero__img2").style.zIndex = 1;
    document.getElementById("hero__img2").style.overflow = "hidden";

    document.getElementById("hero__div1").style.overflow = "hidden";
    // document.getElementById("hero__video").style.opacity = 0;
  }
}

  useEffect(() => {
    window.onscroll = function () {
      scrollFunction();
      // itemMains()
    };
  }, []);

  const itemMain = {
    hidden: {
      position: "relative",
      width: "30vw",
      height: "35vw",
      marginTop: "25vw",
      marginLeft: "33vw",
      overflow: "hidden",
      autoPlay: false,
    },
    show: {
      position: "relative",
      width: "100vw",
      height: "100vh",
      marginTop: "0vw",
      marginLeft: "0vw",
      objectFit: "cover",
      autoPlay: true,
      zIndex: "-999",
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 1.5,
        delay: 3,
      },
    },
    exit: {
      opacity: 1,
    },
  };

  // const ref = useRef()

  // const [reveal, setReveal] = useState(false)
  // const onScreen = useOnScreen(ref);
  // useEffect(() => {
  //   if (onScreen == document.body.scrollTop > -80 || document.documentElement.scrollTop > -80){
  //     setReveal(onScreen)
  //   }
  // }, [onScreen])

  // useEffect(() => {
  //   if(reveal) {
  //     gsap.fromTo(".hero__video", {
  //       position: "relative",
  //       width: "30vw",
  //       height: "35vw",
  //       top: "25vw",
  //       left: "33vw",
  //     }, {
  //       position: "relative",
  //       width: "100vw",
  //       height: "100vh",
  //       top: "0vw",
  //       left: "0vw",
  //       objectFit: "cover",
  //       zIndex: "-999",
  //     })
  //   }
  // }, [reveal])

  return (
    <div className="hero__div1">
      <div className="hero__div00">
        <div className="hero__div2">
          <div id="hero__div4" className="hero__div4">
            <img
              id="hero__img2"
              src={building}
              className="hero__img2"
              alt=""
            />
            <img
              id="hero__img1"
              src={background}
              className="hero__img1"
              alt=""
            />
            <div className="tagline">
              Our office
            </div>
          </div>
          <motion.div className="hero__div3" id="hero__div3">
            <motion.video
              id="hero__video"
              src={pvideo}
              autoPlay={true}
              loop
              muted
              className={`hero__video`}
            ></motion.video>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;

// variants={itemMain} initial="hidden" animate="show" exit="exit"
