import React, { useState } from "react";
import "../../styles/contact.css";

import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

import contactIMG from "../../assets/contactForm.jpg"
// import Navbar from '../Navbar/Navbar'
// import Bg_Image from "../../assets/contact-banner-1.png"



function Contact() {
  // const handleForm = () => {
  //   document.getElementById("form").style.display = "block";
  //   document.getElementById("form_title").style.display = "none";
  // };

  const [formState, setFormState] = useState({})

  // const config = {
    // Username: "kazuokaze@yopmail.com",
    // Password: "AC3752822D00E90C0D10603C1B85B3FC19E5",
    // Host: "smtp.elasticemail.com",
    // Port: 2525,
    // SecureToken: "3d3f0b16-5b77-439d-b37f-3c08e7c2e0f7",
    // To : 'them@website.com',
    // From : "you@isp.com",
    // Subject : "This is the subject",
    // Body : "And this is the body"
  // } 3d3f0b16-5b77-439d-b37f-3c08e7c2e0f7

  const changeHandler = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  }

  const submitHandler = (event) => {
    event.preventDefault()
    const config = {
      SecureToken: "d47dc202-ea8f-4b35-ab54-311296b061a8",
      To : 'info@pixelideas.site, info@parikhrealestate.in',
      From : 'parikhwebsite@gmail.com',
      Subject : "This is a contact page form info",
      Body : `User Email: ${formState.email} 
      User first name: ${formState.first_name}
      Last Name: ${formState.last_name} 
      Phone Number: ${formState.phone}`,
    }
    if(window.Email) {
      window.Email.send(config).then((message) => alert('Thank you for getting in touch with us. We will get back to you within 24 hours'))
    }
    setFormState("");
  }

  // function sendemail(e) {
  //   e.preventDefault();

  //   emailjs.sendForm('gmail', 'template_n4sbg0f', e.target, 'q-TGBsRZ8MQqNt9vS')
  //     .then((result) => {
  //         console.log(result.text);
  //     }, (error) => {
  //         console.log(error.text);
  //     });

  //     e.target.reset();
  // }



  return (
    <div>
      {/* <Navbar /> */}
      <section className="contact_head">
        <h1>Contact Us</h1>
        <h2>
          Feel free to drop into our office, give us a call or even contact us
          via the short form below, and a member of our friendly team will be in
          touch.
        </h2>
      </section>

      <div className="contact_Form_head">
        <h1>Need help with something else?</h1>
        <h1>Get in touch.</h1>
      </div>

      <div className="contact_form">
        <div className="container">
          <img src={contactIMG} alt="" className="contactImg" />
          <div className="address">
            <div className="">
              <div className="">
                <div className="contactFlex">
                  <LocalPhoneIcon />
                  <a href="tel:079 -29608353" target="_blank" rel="noreferrer">
                    079 -29608353
                  </a>
                </div>
                <div className="contactFlex">
                  <EmailIcon />
                  <a
                    href="mailto:info@parikhrealestate.in"
                    target="_blank"
                    rel="noreferrer"
                  >
                    info@parikhrealestate.in
                  </a>
                </div>
              </div>

              <div className="addressContent contactFlex">
                <LocationOnIcon />
                <div className="">
                    <a href="https://goo.gl/maps/9N8Y5SeNkRdCKxCq9" target="_blank" rel="noreferrer" style={{cursor: "pointer"}}>
                    <p>Parikh House,</p>
                    <p>Opposite Lane of Hotel Taj Skyline,</p>
                    <p>Off S.P. Ring Road, Ambli,</p>
                    <p>Ahmedabad - 380058</p>
                    <p>Gujarat, India</p>
                    </a>
                </div>
              </div>
              <div className="contactFlex Timing">
                  <AccessTimeFilledIcon />
                  <div>
                    <p>Monday To Saturday</p>
                    <p>10:00 AM To 7:00 PM</p>
                  </div>
              </div>
            </div>
            {/* <hr className='address_bar' /> */}
          </div>
        </div>
        {/* <h2 id="form_title">Contact Form</h2> */}
        <div className="Form" id="form">
          <form action="" onSubmit={submitHandler}>
            <label htmlFor="">
              First name <span>*</span>
            </label>
            <input type="text" required name="first_name" value={formState.first_name || ''} onChange={changeHandler} />
            <label htmlFor="">
              Last name <span>*</span>
            </label>
            <input type="text" required name="last_name" value={formState.last_name || ''} onChange={changeHandler} />
            <label htmlFor="">
              Email address <span>*</span>
            </label>
            <input type="email" required name="email" value={formState.email || ''} onChange={changeHandler} />
            <label htmlFor="">
              Phone 
            </label> 
            <input type="text" name="phone" maxLength="10" value={formState.phone || ''} onChange={changeHandler} />
            {/* <label htmlFor="">
              Message<span>*</span>
            </label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              style={{ resize: "none" }}
              required
            ></textarea> */}
            <button type="submit">Submit form</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
