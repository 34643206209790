import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import { Route, Switch } from "react-router-dom";
import Home from "./components/home/Home";
import Blog from "./components/Blog/Blog";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import { AnimatePresence } from "framer-motion";
import About from "./components/about/About";
import Resendial from "./components/resedintal/Resendial";
import Buy from "./components/resedintal/Buy";
import Sell from "./components/resedintal/Sell";
import Rent from "./components/resedintal/Rent";
import Capital from "./components/resedintal/Capital";
import First from "./components/Blog/blogPages/First";
import Second from "./components/Blog/blogPages/Second";
import Third from "./components/Blog/blogPages/Third";
import Fourth from "./components/Blog/blogPages/Fourth";
import Fifth from "./components/Blog/blogPages/Fifth";
import Sixth from "./components/Blog/blogPages/Sixth";
import Article from "./components/Article/Article";
import Seventh from "./components/Blog/blogPages/TwentySeventh";
import Eight from "./components/Blog/blogPages/Eight";
import Ninth from "./components/Blog/blogPages/Ninth";
import Tenth from "./components/Blog/blogPages/Tenth";
import Eleventh from "./components/Blog/blogPages/Eleventh";
import Tweleth from "./components/Blog/blogPages/Tweleth";
import Thirteenth from "./components/Blog/blogPages/Thirteenth";
import Fourteenth from "./components/Blog/blogPages/Fourteenth";
import Fifteenth from "./components/Blog/blogPages/Fifteenth";
import Sixteenth from "./components/Blog/blogPages/Sixteenth";
import Seventeenth from "./components/Blog/blogPages/Seventeenth";
import Eighteenth from "./components/Blog/blogPages/Eighteenth";
import Ninteenth from "./components/Blog/blogPages/Ninteenth";
import Twentieth from "./components/Blog/blogPages/Twentieth";
import TwentyFirst from "./components/Blog/blogPages/TwentyFirst";
import TwentySecond from "./components/Blog/blogPages/TwentySecond";
import TwentyThird from "./components/Blog/blogPages/TwentyThird";
import TwentyFourth from "./components/Blog/blogPages/TwentyFourth";
import TwentyFifth from "./components/Blog/blogPages/TwentyFifth";
import TwentySixth from "./components/Blog/blogPages/TwentySixth";
// import TwentySeventh from './components/Blog/blogPages/TwentySeventh';

import ReactGA from "react-ga4";

ReactGA.initialize("G-N850TS548K");
ReactGA.send("pageview");

function App() {
  return (
    <AnimatePresence initial={true} exitBeforeEnter>
      <div className="App">
        <Navbar />
        <Switch>
          <Route path="/blog/first" exact>
            <First />
          </Route>
          <Route path="/blog/second" exact>
            <Second />
          </Route>
          <Route path="/blog/third" exact>
            <Third />
          </Route>
          <Route path="/blog/fourth" exact>
            <Fourth />
          </Route>
          <Route path="/blog/fifth" exact>
            <Fifth />
          </Route>
          <Route path="/blog/sixth" exact>
            <Sixth />
          </Route>
          <Route path="/blog/seventh" exact>
            <Seventh />
          </Route>
          <Route path="/blog/eighth" exact>
            <Eight />
          </Route>
          <Route path="/blog/ninth" exact>
            <Ninth />
          </Route>
          <Route path="/blog/tenth" exact>
            <Tenth />
          </Route>
          <Route path="/blog/eleventh" exact>
            <Eleventh />
          </Route>
          <Route path="/blog/twelfth" exact>
            <Tweleth />
          </Route>
          <Route path="/blog/thirteenth" exact>
            <Thirteenth />
          </Route>
          <Route path="/blog/fourteenth" exact>
            <Fourteenth />
          </Route>
          <Route path="/blog/fifteenth" exact>
            <Fifteenth />
          </Route>
          <Route path="/blog/sixteenth" exact>
            <Sixteenth />
          </Route>
          <Route path="/blog/seventeenth" exact>
            <Seventeenth />
          </Route>
          <Route path="/blog/eighteenth" exact>
            <Eighteenth />
          </Route>
          <Route path="/blog/nineteenth" exact>
            <Ninteenth />
          </Route>
          <Route path="/blog/twentieth" exact>
            <Twentieth />
          </Route>
          <Route path="/blog/twenty-first" exact>
            <TwentyFirst />
          </Route>
          <Route path="/blog/twenty-second" exact>
            <TwentySecond />
          </Route>
          <Route path="/blog/twenty-third" exact>
            <TwentyThird />
          </Route>
          <Route path="/blog/twenty-fourth" exact>
            <TwentyFourth />
          </Route>
          <Route path="/blog/twenty-fifth" exact>
            <TwentyFifth />
          </Route>
          <Route path="/blog/twenty-sixth" exact>
            <TwentySixth />
          </Route>
          <Route path="/blog" exact>
            <Blog />
          </Route>
          <Route path="/contact" exact>
            <Contact />
          </Route>
          <Route path="/about" exact>
            <About />
          </Route>
          <Route path="/article" exact>
            <Article />
          </Route>
          <Route path="/Our-Services/commercial" exact>
            <Buy />
          </Route>
          <Route path="/Our-Services/key-industrial" exact>
            <Sell />
          </Route>
          <Route path="/Our-Services/residential" exact>
            <Rent />
          </Route>
          <Route path="/Our-Services" exact>
            <Resendial />
          </Route>
          <Route path="/Our-Services/capital-market" exact>
            <Capital />
          </Route>
          <Route path="/" exact>
            <Home />
          </Route>
        </Switch>
        <div className="app__div2">
          <Footer />
        </div>
      </div>
    </AnimatePresence>
  );
}

export default App;
